import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { LoginService } from '../services/backoffice/login-service.service';

@Injectable({
  providedIn: 'root'
})
export class BackofficeBearerTokenInterceptorService implements HttpInterceptor {

  constructor(
    private loginService: LoginService,
    private router: Router,
  ) { }

  // Intercept bearer token for Backoffice
  intercept(
    req: HttpRequest<any>, 
    next: HttpHandler,
    ): Observable<HttpEvent<any>> {
    // Gets local storage item
    let backofficeUserData = this.loginService.getBackofficeUserData();

    if (backofficeUserData) {
      // converts string to object
      const modifiedReq = req.clone({
          headers: req.headers.set("Authorization",
              "Bearer " + backofficeUserData.Token)
      });

      return next.handle(modifiedReq);
    } else {
      console.log('Forced Logout due to COOKIE not found');
      this.loginService.logout();
      // Navigate to login
      this.router.navigate(['/login']);
      return next.handle(req);
    }
  }
}
