import { Injectable } from '@angular/core';
import { BackofficeLoginPayload } from '../../models/core/payloads/backoffice-login-payload';
import { BackofficeLoginResponse } from '../../models/core/responses/backoffice-login-response';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import { HttpClient } from '@angular/common/http';
import * as moment from "moment";
import { CookieService } from 'ngx-cookie-service';
import { FunctionAccess } from '../../models/core/backoffice/function-access';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private getApiUrl = environment.dc20ShippingManagerAPIUrl + '/backoffice/login';

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
  ) { }

  login(backofficeLoginPayload: BackofficeLoginPayload): Observable<BackofficeLoginResponse> {
    console.log('backofficeLoginPayload ' , backofficeLoginPayload);
    // Set withCredentials header
    return this.http.post<BackofficeLoginResponse>(this.getApiUrl, backofficeLoginPayload);
  }

  setBackofficeUserData(backofficeLoginResponse: BackofficeLoginResponse) {
    // Set object in cookies
    this.cookieService.set("backoffice-user-data", JSON.stringify(backofficeLoginResponse), 1, '/' );
  }   
  
  getBackofficeUserData() : BackofficeLoginResponse {
    // Get object from cookies
    let sBackofficeUserData = this.cookieService.get("backoffice-user-data");
    
    if(sBackofficeUserData){
      return JSON.parse(sBackofficeUserData) as BackofficeLoginResponse;
    } else {
      return undefined;
    }
  } 

  getBackofficeUserFunctions() : FunctionAccess[] {
    // Get object from cookies
    let sBackofficeUserData = this.cookieService.get("backoffice-user-data");
    
    if(sBackofficeUserData){
      let bur = JSON.parse(sBackofficeUserData) as BackofficeLoginResponse;

      if(bur){
        return bur.Functions;
      } else {
        return [];
      }
    } else {
      return [];
    }
  } 

  logout() {
    console.log('remove cookie');
    // Remove object from cookies
    this.cookieService.delete("backoffice-user-data", '/');
  }

  isLoggedIn() {
    return this.getBackofficeUserData();
  }

  isLoggedOut() {
    return !this.isLoggedIn();
  }
}
