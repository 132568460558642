import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieService } from 'ngx-cookie-service';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { BackofficeBearerTokenInterceptorService } from '../app/_lib/_interceptors/backoffice-bearer-token-interceptor.service';
import { UnauthorizedResponseInterceptorService } from '../app/_lib/_interceptors/unauthorized-response-interceptor.service';


import { IconSetService } from '@coreui/icons-angular';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { AppComponent } from './app.component';


// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
  ],
  exports: [
    RouterModule,
  ],
  declarations: [
    AppComponent,
  ],
  providers: [
    // Platform header interceptor
    { provide: HTTP_INTERCEPTORS, useClass: BackofficeBearerTokenInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UnauthorizedResponseInterceptorService, multi: true },
    IconSetService,
    CookieService,
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
