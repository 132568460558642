import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../../../environments/environment';
import {tap} from 'rxjs/operators';
import { LoginService } from '../services/backoffice/login-service.service';
import { Router } from '@angular/router';

@Injectable()
export class UnauthorizedResponseInterceptorService implements HttpInterceptor {

  constructor(
    private loginService: LoginService,
    private router: Router,
  ) { }
  
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe( tap(() => {},
      (resp: any) => {
      console.warn(resp);
      if (resp instanceof HttpErrorResponse) {
        // If the response coming from the API and contains 401 unauthorized status code it redirects to login
        if (resp.status == 401 && resp.url.startsWith(environment.dc20ShippingManagerAPIUrl)) {
          console.log('Forced Logout due to UNAUTHORIZED status coming from API');
          this.loginService.logout();
          // Navigate to login
          this.router.navigate(['/login']);
        } else {
          return;
        }
      }
    }));
  }
}
